<template>
	<BaseLoading v-if="edit.isLoading" is-full-page />
	<ProductFamilyForm
		v-else
		:product-family="productFamily"
		:product-family-id="productFamilyId"
		is-edit
	/>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ProductFamilyForm from '../components/ProductFamilyForm.vue';

export default {
	name: 'ProductFamilyEdit',

	components: {
		ProductFamilyForm,
	},

	data() {
		return {
			productFamilyId: this.$route.params.id,
		};
	},

	computed: {
		...mapState('productFamilies', {
			edit: 'edit',
		}),
		productFamily() {
			return this.edit.data;
		},
	},

	async mounted() {
		await this.getProductFamilies(this.productFamilyId);
	},


	methods: {
		...mapActions({
			getProductFamilies: 'productFamilies/getProductFamily',
		}),
	},
};
</script>
